import {GridSortCellParams, GridSortItem} from '@mui/x-data-grid-premium'
import format from 'date-fns/format'
import escapeStringRegexp from 'escape-string-regexp'

type PluralizeFunc = (
	n: number,
	singular: string,
	plural: string,
	accusative: string
) => string

export const pluralize: PluralizeFunc = (n, singular, plural, accusative) => {
	n = Math.abs(Number(n))
	const n10 = n % 10
	const n100 = n % 100
	if (n10 === 1 && n100 !== 11) {
		return singular
	}
	if (n10 >= 2 && n10 <= 4 && !(n100 >= 12 && n100 <= 14)) {
		return plural
	}
	return accusative
}

export const divideNumber = (n: number): string =>
	String(n).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const wait = (delay: number): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, delay))

type DateFormatter = (date: Date) => string

export const dateFormatter: DateFormatter = date => format(date, 'dd.MM.yyyy')

export const dateHoursFormatter: DateFormatter = date =>
	format(date, 'dd.MM.yyyy HH:mm')

type IsNumericFunc = (num: number | string | undefined) => boolean

export const isNumeric: IsNumericFunc = num =>
	typeof num === 'number' || /^-?[0-9]+(?:\.[0-9]+)?$/.test(String(num))

type ObjectCountKeysFunc = (obj: object) => number

export const objectCountKeys: ObjectCountKeysFunc = obj =>
	Object.keys(obj).length

type FilterObjectFunc = <T>(
	obj: Record<PropertyKey, T>,
	f: (key: string, value?: T, index?: number) => boolean
) => Record<string, Partial<T>>

export const filterObject: FilterObjectFunc = (obj, f) =>
	Object.fromEntries(
		Object.entries(obj).filter((pair, index) => f(pair[0], pair[1], index))
	)

type MapObjectFunc = <T, U>(
	obj: Record<PropertyKey, T>,
	f: (key: string, value?: T, index?: number) => [PropertyKey, U]
) => Record<PropertyKey, U>

export const mapObject: MapObjectFunc = (obj, f) =>
	Object.fromEntries(
		Object.entries(obj).map((item, index) => f(item[0], item[1], index))
	)

type ArrayEndFunc = <T>(arr: T[]) => null | T

export const arrayEnd: ArrayEndFunc = arr =>
	arr.length === 0 ? null : arr[arr.length - 1]

type StringEndFunc = (str: string) => null | string

export const stringEnd: StringEndFunc = str =>
	str.length === 0 ? null : str[str.length - 1]

export const removeSpaces = (str: string): string => str.replace(/\s/g, '')

type ArticleData = {
	article: string
	note: string | null
}

type TransformArticlesFunc = (articles: string[]) => ArticleData[]

export const transformArticles: TransformArticlesFunc = articles =>
	articles
		.map(trim)
		.filter(item => /^\d{5,}(.+)?$/.test(item))
		.map(item => {
			const entities = item.match(/^(\d{5,})(.+)?$/)!
			const article: string = entities[1]
			let note = null
			if (entities[2]) {
				note = trim(entities[2]).replace(/^[-–—]+ ?/, '') || null
			}
			return {article, note}
		})
		.filter(
			(item, index, array) =>
				array.findIndex(j => j.article === item.article) === index
		)

export const trim = (str: string | number): string =>
	String(str).trim().replace(/\n+/, ' ').replace(/ +/g, ' ')

export const getWBProductLink = (article: string): string =>
	`https://www.wildberries.ru/catalog/${article}/detail.aspx`

export const getWBBrandLink = (brandUrl: string): string =>
	`https://www.wildberries.ru${brandUrl}`

type FilterThenMapFunc = <T, U>(
	arr: T[],
	filterFunction: (item: T) => boolean,
	mapFunction: (item: T) => U,
	debug?: boolean
) => U[]

export const filterThenMap: FilterThenMapFunc = (
	arr,
	filterFunction,
	mapFunction,
	debug
) => {
	const result = []
	for (let i = 0; i < arr.length; i++) {
		const item = arr[i]
		if (!filterFunction(item)) {
			debug && console.warn(`Filtered item:`, {array: arr, item})
			continue
		}
		result.push(mapFunction(item))
	}
	return result
}

export const getCookie = (
	key: string
): string | undefined | {[key: string]: string} => {
	if (document.cookie === '') return undefined
	const cookie = document.cookie
		.split(';')
		.map(v => v.split('='))
		.reduce<Record<string, string>>((acc, v) => {
			acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
			return acc
		}, {})
	if (key) return cookie[key]
	return cookie
}

export const addCookie = (
	key: string, // Имя куки
	value: string, // значение
	days?: number, // кол-во дней жизни куки
	path: string = '/' // путь для которого куки будут доступны
): void => {
	let expires = ''

	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = `; expires=${date.toUTCString()}`
	}

	document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
		value
	)}${expires}; path=${path}`
}

export const isEmpty = (value: any) =>
	value === null || value === undefined || value === ''

// todo: удалить если не успользуется
export const sortComparator = (
	a: any,
	b: any,
	rowA: GridSortCellParams
	//rowB: GridSortCellParams
) => {
	const direction =
		rowA.api
			.getSortModel()
			.find((item: GridSortItem) => item.field === rowA.field)?.sort || 'desc'
	if (typeof a === 'string' || typeof b === 'string') {
		return String(b).localeCompare(String(a))
	}
	if (isEmpty(a) && isEmpty(b)) {
		return 0
	}
	if (isEmpty(a)) {
		return direction === 'asc' ? 1 : -1
	}
	if (isEmpty(b)) {
		return direction === 'asc' ? -1 : 1
	}
	return a - b
}

export const disableDataGridLicenseError = () => {
	const originConsoleError = window.console.error
	window.console.error = (...args: unknown[]) => {
		if (
			!(
				args.length <= 2 &&
				typeof args[0] === 'string' &&
				args[0].indexOf('MUI: Missing license key') > 0
			)
		) {
			originConsoleError(...args)
		}
	}
}

export const firstUppserCase = (str: string): string => {
	if (str === '') return ''
	if (str.length === 1) return str.toUpperCase()
	return `${str[0].toUpperCase()}${str.slice(1)}`
}

export const sortComparatorForPositions = (
	valueA: any,
	valueB: any,
	rowA: GridSortCellParams,
	rowB: GridSortCellParams,
	ISODateString: string
) => {
	const direction = rowA.api
		.getSortModel()
		.find((item: GridSortItem) => item.field === rowA.field).sort
	const newRowA = rowA.api.getRow(rowA.id).positionsByDate[ISODateString]
	const newRowB = rowB.api.getRow(rowB.id).positionsByDate[ISODateString]

	const newValueA =
		typeof valueA === 'number'
			? valueA * 100000 + (newRowA.beforePromotion || 0)
			: null

	const newValueB =
		typeof valueB === 'number'
			? valueB * 100000 + (newRowB.beforePromotion || 0)
			: null

	if (newValueA === newValueB) {
		return 0
	}
	if (newValueA === null) {
		return direction === 'asc' ? 1 : -1
	}
	if (newValueB === null) {
		return direction === 'asc' ? -1 : 1
	}
	// Оба значения не равны null, продолжаем сравнивать их
	if (newValueA !== newValueB) {
		return newValueA - newValueB
	}
	if (newRowA.hasPromotion && !newRowB.hasPromotion) {
		return 1
	}
	if (!newRowA.hasPromotion && newRowB.hasPromotion) {
		return -1
	}
	return 0
}

export const objectToUrlParams = (obj: Record<string, any>) => {
	const usp = new URLSearchParams()
	for (const key in obj) {
		usp.append(key, obj[key])
	}
	return usp.toString()
}

type Chunk = {
	start: number
	end: number
}

type FindChunksFunc = (params: {
	searchWords: string[]
	textToHighlight: string
}) => Chunk[]

export const findChunks: FindChunksFunc = ({searchWords, textToHighlight}) => {
	const result: Chunk[] = []
	const textToHighlightTransformed = textToHighlight
		.replace(/[ёË]/g, 'e')
		.replace(/[^A-Za-zА-Яа-яёË0-9 ]/gm, ' ')
	searchWords.forEach(word => {
		const escapedWord = escapeStringRegexp(word.replace(/[ёË]/g, 'e'))
		const matchedWords = textToHighlightTransformed.matchAll(
			new RegExp(
				`^${escapedWord}$|^${escapedWord} | ${escapedWord}$| ${escapedWord} `,
				'ig'
			)
		)
		for (const matchedWord of matchedWords) {
			result.push({
				start: matchedWord.index!,
				end: matchedWord.index! + matchedWord[0].length,
			})
		}
	})
	return result
}

export const getProductCharacteristics = (
	characteristics: Array<{
		name: string
		value: string
	}>
) => {
	const _characteristics = characteristics.filter(
		item => !['Название', 'Описание'].includes(item.name)
	)
	if (_characteristics[0].name === 'Комплектация') {
		return _characteristics.slice(1)
	}
	return _characteristics
}

export const startsFromNumber = (str: string): boolean => /^[0-9]/.test(str)
